<template>
    <div class="vip-content">
        <div class="vip-title">
            <img src="@/assets/img/i-tit2.png" alt="">
            我的积分
        </div>
        <div class="vip-content-inner">
            <div class="mine-integral">
                <div class="top">
                    <img src="@/assets/img/integral-bg.png" class="bg" alt="">
                    <div class="text">
                        <h3>{{ customJFtotal }}</h3>
                        <p>
                            <img src="@/assets/img/i-integral.png" alt="">
                            积分
                        </p>
                    </div>
                    <div class="g-btn small" @click="goDH">马上兑换</div>
                </div>
                <div class="bot">
                    <p>1)订购产品每消费1元积1分(活动期间积分按活动规则执行)， 积分按消费金额累计计算;</p>
                    <p>2)订单完成后才可获得相应的积分;</p>
                    <p>3)积分目前只能在积分商城内兑换礼品使用，无法兑换现金、抵扣货款;</p>
                    <p>4)积分商城内的礼品只接受积分兑换，恕不接受现金购买，不接受补差价购买;</p>
                    <p>5)积分兑换的礼品与下一次订单产品一起配送;订单一旦生成若无产品质量问题，不予办理礼品退换货;</p>
                    <p>6)天津一方科技保留本积分活动中条款的解释权利，并有权根据需要取消本活动或增删、修订本规定的权利，并于天津一方科技官网公布后生效。</p>
                    <p>如有疑问，请致电天津一方科技客服中心400-185-8866咨询。</p>
                </div>

            </div>


        </div>
    </div>
</template>

<script>

import {userDetail} from "@/api/account";

export default {
    name: "mine",
    data() {
        return {
            customJFtotal:0, // 积分总数
        }
    },
    mounted() {
        this.getuserDetail() // 获取用户信息
    },
    methods:{
      goDH(){
          this.$router.push({path:'/integralMall'})
      },

        getuserDetail(){ // 获取用户信息
            console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
            let userId=JSON.parse(localStorage.getItem('userInfo')).userId
            userDetail({userId:userId}).then((res) => {
                if (res.data.code==200) {
                    localStorage.setItem('customInfo', JSON.stringify(res.data.data)); // 登录用户信息
                    localStorage.setItem('customId', res.data.data.id)
                    this.customJFtotal=JSON.parse(localStorage.getItem('customInfo')).integralTotal || 0

                }

            })
        },

    },
}
</script>

<style scoped>

</style>
